.calendar {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

#calendar-selectors {
  margin-left: 50px;
}

#calendar-selectors select {
  width: 180px;
}

#calendar-selectors .MuiFormControlLabel-root {
  margin-bottom: 20px;
}
/* Day Header */
.ui.celled.table tr th {
  background-color: white;
  position: sticky;
  top: 0;
  font-size: 11px;
  color: var(--color-gray);
  text-transform: upperCase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
}

.table-day {
  color: var(--color-darkGray);
  font-weight: 400;
  font-size: 25px;
  letter-spacing: -2.5px;
  text-indent: -2.5px;
  line-height: 46px;
  border-radius: 100%;
  width: 46px;
  height: 46px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

th.active .table-day {
  background-color: var(--color-pink);
  color: white;
}

.ui.celled.table tr th.active {
  color: var(--color-pink);
}

/* time slot */

td.time-slot {
  height: 35px;
  overflow: visible;
  cursor: pointer;
}

.ui.table td.time-slot.active {
  border-top: none;
}

.ui.table td.time-slot.header {
  color: var(--color-lightGray);
  border: none;
}

td.time-slot.disabled {
  background-color: var(--color-backgroundGrayTransparent);
}

/* Event Overlay */

.events {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 5px;
}

.available {
  background-color: var(--color-transparentPink);
  color: var(--color-lightGray);
}

.booked {
  background-color: var(--color-accent);
  z-index: 100;
  color: whitesmoke;
}

.booking-info {
  text-transform: lowercase;
  position: absolute;
  overflow-wrap: break-word;
  width: calc(100% / 8);
  font-weight: 300;
}

.booking-info b {
  font-weight: 800;
}

/* react calendar */

.react-calendar {
  border-radius: 15px;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: var(--color-darkGray);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--color-lightGray);
}

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active {
  background: var(--color-pink);
  color: white;
}

.ui.table td {
  padding: 0;
}

/* Shift Modal */

.MuiDrawer-root.MuiDrawer-docked .MuiPaper-root.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiDrawer-paperAnchorDockedRight {
  width: 100%;
  max-width: 320px;
  background-color: var(--color-backgroundGray);
  box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 20px;
}

.modal-header h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 2em;
  margin: 0;
  color: rgba(0,0,0,.35);
}

.modal-body {
  background-color: white;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}

.MuiInputBase-root {
  margin-bottom: 20px;
}

.ui.button {
  background-color: var(--color-accent);
  color: white;
}

.ui.button:hover,
.ui.button:active,
.ui.button:focus {
  background-color: var(--color-pink);
  color: white;
}

/* booking modal  */

.subheading {
  color: lightgray;
  font-weight: 600;
}

.ui.icon.input {
  width: 100%;
}

.ui.search .prompt {
  border-radius: 5px;
}

#mama-search {
  margin-bottom: 20px;
}

#mama-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

#new-mama-button {
  width: 90px;
  padding: 8px;
  font-size: 11px;
  background-color: lightgray;
}

/* booking -> mama card  */

.ui.card.mama-card {
  margin-top: 0;
}

#card-name {
  font-size: 1em;
}

#card-address{
  font-size: 12px;
}

.ui.card.hide {
  display: none;
}

.ui.fluid.dropdown {
  margin-bottom: 15px;
}