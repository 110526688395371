.warning-box {
  display: flex;
  color: red;
  border: 1px solid red;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
}

.warning-box i {
  margin-right: 10px;
}

.warning-box.hide {
  display: none;
}