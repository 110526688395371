.full-page-outter {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login h1 {
  color: var(--color-pink1);
  font-size: 1.8em;
}

.login {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 350px;
}

.login input {
  width: 250px;
}

.login button {
  margin-top: 20px;
}