:root {
  --color-pink: #F087A0;
  --color-palePink: #F5C3CF;
  --color-transparentPink: #f087a03b;
  --color-darkGray: #444;
  --color-gray: #70757a;
  --color-pink2: #F08EB7;
  --color-pink3: #F4ADA5;
  --color-accent: #f50057;
  --color-lightGray: #b7b7b7;
  --color-backgroundGrayTransparent: #ededed5c;
  --color-backgroundGray: #ededed;
}

@font-face {
  font-family: 'galano-semibold';
  src: url('./galano/galanogrotesquealt-semibold-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-light';
  src: url('./galano/galanogrotesquealt-light-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-reg';
  src: url('./galano/GalanoGrotesque-Regular-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-bold';
  src: url('./galano/GalanoGrotesque-Bold-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

div {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
background-color: var(--color-palePink);
outline: 1px solid var(--color-palePink);
}

a {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
}

.divider {
  margin: 20px 0;
  border-bottom: 1px solid #EBEBEB;
}

.ui.input.focus>input,
.ui.input>input:focus {
  border-color: var(--color-palePink) !important;
}

.pac-logo,
.pac-container {  
  z-index: 9999999 !important;  
  background-color: #fff;
  position: absolute!important;
  /* z-index: 1000; */
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--color-pink) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: var(--color-pink) !important;
}

/* button border */

a.button-outline,
button.button-outline {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 0;
  padding: .75rem 1.5625rem;
  -webkit-transform: translate3d(.375rem,-.375rem,0);
  transform: translate3d(.375rem,-.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: black;
  height: auto;
  margin-bottom: .375rem;
  text-align: center;
  text-decoration: none;
  outline: none;
  min-width: 9rem;
  max-width: 80vw;
  width: 75%;
  font-family: 'galano-bold', sans-serif;
}

a.button-outline:hover,
a.button-outline:hover::before,
button.button-outline:hover,
button.button-outline:hover::before {
  color: black;
  transform: translateZ(0);
}

a.button-outline:before,
button.button-outline:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--color-pink);
  content: "";
  -webkit-transform: translate3d(-.375rem,.375rem,0);
  transform: translate3d(-.375rem,.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  border-radius: 5px;
}

a.button-outline:after,
button.button-outline:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  content: "";
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  border-radius: 5px;
}
